import { Injectable } from '@angular/core';

import {
  clear as _clear,
  get as _get,
  set as _set,
  setMany as _setMany,
} from 'idb-keyval';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  worker: Worker;

  constructor() {
    if (typeof Worker !== 'undefined') {
      // Create a new
      this.worker = new Worker(
        new URL('../compression.worker', import.meta.url)
      );
      this.worker.onmessage = ({ data }) => {
        console.log(`Compression worker: ${data}`);
      };
    } else {
      // Web workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
      console.warn('Web workers are not supported in this environment.');
    }
  }

  set(key: string, value: any, compressed = false) {
    /*
    if (compressed || !this.worker) {
      this.worker.postMessage({ key, value });
      return;
    }


    if (compressed) {
      value = compress(value);
    }
    */

    _set(key, value).catch((err) => console.log('It failed!', err));
  }

  setMany(data: [IDBValidKey, any][], compressed = false) {
    _setMany(data).catch((err) => console.log('It failed!', err));
  }

  async get<T>(key: string, compressed = false): Promise<T | undefined> {
    try {
      const data = await _get<T>(key);
      if (!data) {
        return;
      }

      // assure retrocompatibility with the old compression system
      if (data instanceof Uint8Array) {
        return;
      }

      /*
      if (compressed) {
        console.time('uncompressed' + key);
        // data = decompress(data);
        console.timeEnd('uncompressed' + key);
      }
      */
      return data;
    } catch (err) {
      console.log('It failed!', err);
      return undefined;
    }
  }

  clear() {
    _clear();
  }
}
