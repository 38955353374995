export const environment = {
  releaseDate: '2025-01-09T10:05:08.421Z',

  firebase: {
    projectId: 'brokerage-system',
    appId: '1:476793168644:web:02bb9d88b64314d3076651',
    storageBucket: 'brokerage-system.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyCoA-NIFJa8TwmKHnxY_lQBwOVoNXWnAns',
    authDomain: 'brokerage-system.firebaseapp.com',
    messagingSenderId: '476793168644',
  },

  rollbarConfig: {
    accessToken: 'c1e967d996134b4584bc615f741721c4',
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: 'production',
    enabled: true,
    payload: {
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: '2025-01-09T10:05:08.421Z',
          guess_uncaught_frames: true,
        },
      },
    },
  },

  userbackToken: '31136|72550|2QPQmqtWXYy6xOKjy4IqlLsde',

  production: true,

  apiUrl: 'https://api.bro.etoh.digital/api',
  graphQlUrl: 'https://graphql2.bro.etoh.digital/v1/graphql',

  aftership: {
    token: 'asat_5da5a011dd1740f284b0afee2940cb97',
  },
};
