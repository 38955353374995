import { inject, Injectable } from '@angular/core';
import { DataTypeKeys } from '@etoh/database/core';
import { last, uniq } from 'lodash';
import { BehaviorSubject, bufferTime } from 'rxjs';
import { CacheService } from '../cache/cache.service';

interface CacheObject {
  dataType: DataTypeKeys;
  data: any;
}

@Injectable({
  providedIn: 'root',
})
export class StoreCacheService {
  private cacheService = inject(CacheService);

  nextCacheKey = new BehaviorSubject<CacheObject | null>(null);

  constructor() {
    this.nextCacheKey
      .asObservable()
      .pipe(bufferTime(30000))
      .subscribe((caches) => {
        const filteredCaches = caches.filter((cache) => !!cache);
        if (!filteredCaches.length) {
          return;
        }

        const keysToUpdate = uniq(
          filteredCaches.map((cache) => cache.dataType)
        );

        const lastCachesToUpdate = keysToUpdate.map((key) => {
          const cache = last(filteredCaches.filter((c) => c.dataType === key));

          if (!cache) {
            return;
          }

          return cache;
        });

        const filtered = lastCachesToUpdate.filter((c) => !!c);

        if (!filtered.length) {
          return;
        }

        this.cacheService.setMany(
          filtered.map((c) => {
            return [c.dataType, c.data];
          })
        );
      });
  }

  get<T>(key: string, compressed = false): Promise<T | undefined> {
    return this.cacheService.get<T>(key, true);
  }
}
