import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  inject,
  input,
  OnInit,
  output,
  viewChild,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Items } from '@etoh/database/core';
import {
  FormlyFieldConfig,
  FormlyFormOptions,
  FormlyModule,
} from '@ngx-formly/core';
import { environment } from 'apps/desktop/src/environments/environment';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import {
  combineLatest,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  shareReplay,
  startWith,
  take,
} from 'rxjs';
import { SpeechToTextComponent } from '../speech-to-text/speech-to-text.component';
import { GeneratedItem } from './item-workflow-add.interface';

@Component({
  selector: 'etoh-item-workflow-add',
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FormlyModule,
    NzFormModule,
    SpeechToTextComponent,
    ReactiveFormsModule,
    NzRadioModule,
    NzAlertModule,
    NzIconModule,
    NzUploadModule,
  ],
  template: `
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center start">
      <div fxFlex="50%">
        @if (model.isBulk) {
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <div>
            <button
              type="button"
              nz-button
              nzType="default"
              nzShape="circle"
              (click)="inputFile.click()"
            >
              <span nz-icon nzType="import"></span>
            </button>
          </div>
          <div>Import items from file (.pdf)</div>
        </div>
        <input
          style="display: none"
          #inputFile
          type="file"
          (change)="handleChange($event)"
          accept="application/pdf"
        />
        }
        <!--
        ,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel
      -->
      </div>

      <div fxFlex="50%">
        <etoh-speech-to-text
          (change)="speechToDescription($event)"
        ></etoh-speech-to-text>
      </div>
    </div>
    <br />
    <form nz-form [formGroup]="form" nzLayout="vertical" (ngSubmit)="submit()">
      <formly-form
        [model]="model"
        [fields]="fields()"
        [options]="options"
        [form]="form"
      ></formly-form>
      <div [ngClass]="{ 'example-3': loading }" class="example-3">
        <button
          nz-button
          type="submit"
          [ngClass]="{ loading: loading }"
          nzType="primary"
          [disabled]="!form.valid"
          [nzLoading]="loading"
        >
          {{ submitLabel$ | async }}
        </button>
      </div>

      <br />

      @if (possibleItems$ | async; as possibleItems) {
      <p>Potential generated items: {{ possibleItems.length }}</p>
      }
    </form>
  `,
  styles: `
    .example-3 {
      // weird
  // position: relative;
  border-radius: var(--border-radius);
  padding: 4px;
}

.example-3 .inner {
  border-radius: 4px;
}

.example-3::before,
.example-3::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    45deg,
    #ff595e,
    #ffca3a,
    #8ac926,
    #1982c4,
    #6a4c93,
    #ff6700
  );
  background-size: 400%;
  z-index: -1;
  animation: glow 20s linear infinite;
  width: 100%;
  border-radius: var(--border-radius);
}


.example-3::after {
  filter: blur(25px);
  transform: translate3d(0, 0, 0); /* For Safari */
}

@keyframes glow {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
}
`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemWorkflowAddComponent implements OnInit {
  couldBeBulk = input<boolean>(false);
  form = new FormGroup({
    description: new FormControl<string | null>(null),
    isBulk: new FormControl<boolean>(false),
  });

  submitLabel$: Observable<string> = this.form.valueChanges.pipe(
    startWith(this.form.value),
    map((form) => (form.isBulk ? 'Generate bulk items' : 'Generate item'))
  );
  model: any = {
    description: null,
    isBulk: false,
  };

  possibleItems$ = combineLatest([
    this.form.valueChanges.pipe(startWith(this.form.value)),
  ]).pipe(
    map(([form]) => {
      if (!form.isBulk) {
        return undefined;
      }

      const description = (form?.description ?? '') as string;

      return description
        .split('</p>')
        .filter((r) => r?.length && r.length > 50)
        .map((s) => s.replaceAll('<p>', ''));
    }),
    shareReplay(1)
  );

  loading = false;
  speechComponent = viewChild.required(SpeechToTextComponent);

  options: FormlyFormOptions = {};

  fields = computed<FormlyFieldConfig[]>(() => {
    return [
      {
        fieldGroupClassName: 'ant-row',
        fieldGroup: [
          ...(this.couldBeBulk()
            ? [
                {
                  key: 'isBulk',
                  type: 'radio',
                  // wrappers: ['preview'],
                  className: 'ant-col ant-col-24',
                  props: {
                    /*
              tooltipHelper: 'x',
              extra: 'x',
              label: 'Type',
              */
                    options: [
                      { label: 'Bulk import', value: true },
                      { label: 'Single item', value: false },
                    ],
                  },
                },
              ]
            : []),
          {
            key: 'description',
            type: 'wysiwyg',
            // wrappers: ['preview'],
            className: 'ant-col ant-col-24',
            props: {
              tooltipHelper:
                'More precise you are, more accurate the item will be',
              label: 'Your input specification',
              placeholder:
                '3 hectoliters of bulk red wine from the Bordeaux region, 2019 vintage, organic, 12.5% alcohol, 100% Merlot',
              minLength: 50,
              maxLength: 20000,
              required: true,
            },
            expressions: {
              'props.extra': (field) => {
                const model: any = field.model;
                return model.isBulk
                  ? 'The AI will generate items based on your input.<br>Minimum 50 characters per line'
                  : 'The AI will generate the item based on your input.<br>Minimum 50 characters';
              },
            },
          },
        ],
      },
    ];
  });

  cd = inject(ChangeDetectorRef);
  speechToDescription(event: any) {
    console.log('event', event);
    this.model = {
      ...this.model,
      description: event,
    };
    this.cd.detectChanges();
  }

  generatedItems = output<GeneratedItem[]>();

  http = inject(HttpClient);
  message = inject(NzMessageService);

  ngOnInit() {
    setTimeout(() => {
      if (this.couldBeBulk()) {
        this.form.patchValue({
          isBulk: true,
        });
      }
    });
  }

  async submit() {
    this.loading = true;
    this.speechComponent().stopListening();

    this.message.loading('AI is working on your request');

    if (this.model.isBulk) {
      const possibleItems = await lastValueFrom(
        this.possibleItems$.pipe(take(1))
      );

      if (!possibleItems || !possibleItems.length) {
        return;
      }

      const partialItems = await Promise.all(
        possibleItems.map((a) =>
          firstValueFrom(this.makeRequest(a).pipe(take(1)))
        )
      );

      const partialItemsWithDescription = partialItems.map((a, index) => ({
        item: a,
        input: possibleItems[index],
      }));

      this.generatedItems.emit(partialItemsWithDescription);
    } else {
      const description = this.model.description;
      this.makeRequest(description).subscribe((res) => {
        this.loading = false;
        this.message.success(
          'Item generated, you can now review it on the form'
        );

        if (res) {
          this.generatedItems.emit([
            {
              item: res,
              input: description,
            },
          ]);
        }
      });
    }
  }

  private makeRequest(input: string): Observable<Partial<Items>> {
    return this.http
      .post(`${environment.apiUrl}/items/augmentItem`, { input })
      .pipe(map((res: any) => res?.item));
  }

  handleChange(ev: any): void {
    const fileToUpload = ev.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      this.message.loading('We are processing your file');
      const fileBase64 = reader.result as string;
      this.http
        .post(`${environment.apiUrl}/items/fileToLines`, {
          input: fileBase64,
          // fileType: fileToUpload.type,
        })
        .subscribe((res: any) => {
          const lines = this.extractJsonArray(res.answer as string);
          if (lines.length === 0) {
            this.message.info('File processed, but no valid lines found');
          } else {
            this.message.success('File processed successfully');
          }
          this.form.patchValue({
            description: lines.join('</p><p>'),
          });
        });
    };

    reader.readAsDataURL(fileToUpload);
  }

  private extractJsonArray(input: string): string[] {
    const jsonArrayMatch = input.match(/\[\s*("[^"]*"\s*(,\s*"[^"]*"\s*)*)\]/);
    if (jsonArrayMatch) {
      const jsonArrayString = jsonArrayMatch[0];
      try {
        return JSON.parse(jsonArrayString);
      } catch (error) {
        console.error('Failed to parse JSON array:', error);
        return [];
      }
    } else {
      console.error('No JSON array found in the input string.');
      return [];
    }
  }
}
